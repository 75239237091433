<template>
    <!-- Login Modal -->
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <div class="registration">
                    <div class="card">
                        <div class="card-body">
                            
                                <span class="shape"></span>
                                <div class="row" v-if="errMsgFlag">
                                    <div class="col-lg-12">
                                        <div class="alert alert-danger h5 bg-danger text-light p-2 alert-dismissible fade show" role="alert">{{errMsg}}</div>
                                    </div>
                                </div>
                                <div class="row" v-if="msgFlag">
                                    <div class="col-lg-12">
                                            <div class="alert alert-success h5 bg-success text-light p-2  alert-dismissible fade show" role="alert">{{msg}}</div>
                                    </div>
                                </div>
                                <slot v-if="signUpForm">
                                    <form  class="row p-5" @submit.prevent>
                                        <div class="col-lg-12 form-group text-center">
                                            <h3>Registration</h3>
                                        </div>
                                        <div class="col-lg-12 form-group">
                                            <label for="Name">Name</label>
                                            <input type="text" class="form-control shadow-none" placeholder="Name" v-model="name">
                                        </div>
                                        <!-- <div class="col-lg-12 form-group">
                                            <label for="Email">Email</label>
                                            <input type="text" class="form-control shadow-none" placeholder="Email" v-model="email">
                                        </div> -->
                                        <div class="col-lg-12 form-group">
                                            <label for="Phone">Phone</label>
                                            <input type="text" class="form-control shadow-none" placeholder="Phone" v-model="phone">
                                        </div>
                                        
                                        <div class="col-lg-12 form-group">
                                            <label for="Password">Password</label>
                                            <input type="password" class="form-control shadow-none" placeholder="Password" v-model="password">
                                        </div>
                                        <div class="col-lg-12 form-group">
                                            <label for="Confirm Password">Confirm Password</label>
                                            <input type="password" class="form-control shadow-none" placeholder="Confirm Password" v-model="conPassword">
                                        </div>
                                        <div class="col-lg-12 form-group text-center">
                                            <button type="submit" class="btn btn-primary shadow-none" v-on:click="verifyCustomerInfo()">Submit</button>
                                        </div>
                                    </form>
                                </slot>
                                <slot v-if="otpForm">
                                     <form  class="row p-5" @submit.prevent>
                                        <div class="col-lg-12 form-group text-center">
                                            <h3>Verification</h3>
                                        </div>
                                        <div class="col-lg-12 form-group">
                                            <label for="Name">Name</label>
                                            <label type="text" class="form-control shadow-none" >{{name}}</label>
                                        </div>
                                        
                                        <div class="col-lg-12 form-group">
                                            <label for="Phone">Phone</label>
                                            <label type="text" class="form-control shadow-none" >{{phone}}</label>
                                        </div>
                                        
                                        <div class="col-lg-12 form-group">
                                            <label for="Password">One Time Password</label>
                                            <input type="password" class="form-control shadow-none" placeholder="Enter One Time Password" v-model="otp">
                                        </div>
                                        <div class="col-lg-12 form-group">
                                            <div class="row no-gutters">
                                                <div class="col-lg-6">
                                                    <slot v-if="countDown>0">
                                                        <a href="#" class="text-danger">Resend OTP  in {{ countDown }}s</a>
                                                    </slot>
                                                    <slot v-else>
                                                        <a href="#" @click="verifyCustomerInfo()">Resend OTP </a>
                                                    </slot>
                                                </div>
                                                <div class="col-lg-6 text-right">
                                                    <a href="#" @click="signUpForm=true;otpForm=false;">Retype Info</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 form-group text-center">
                                            <button type="submit" class="btn btn-primary shadow-none" v-on:click="signUpFunction()">Registration</button>
                                        </div>
                                    </form>
                                </slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>
    <!-- /Login Modal -->
</template>

<script>
export default {
data() {
        return {
            name:null,
            phone:null,
            email:null,
            password:null,
            conPassword:null,
            msgFlag:false,
            errMsgFlag:false,
            msg:null,
            errMsg:null,
            signUpForm:true,
            otpForm:false,
            countDown:120,
            otp:null,
        }
    },
     beforeMount(){
     // this.countDownTimer();
    },
    methods:{
        resetRegistrationForm(){
            this.name=null;
            this.phone=null;
            this.email=null;
            this.password=null;
            this.conPassword=null;
            this.otp=null;
            this.otpForm=false;
            this.signUpForm=true;
        },
        countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
         },
        emailValidation() 
        {
            let pattern=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let flag=pattern.test(this.email.trim());
            if(flag)
                return true;
            else
                return false;
        },
       signUpFormValidation(){
        if(this.name!=null && this.phone!=null && this.password!=null && this.conPassword!=null){
            if(this.phoneNumberValidation()){
                if(this.password==this.conPassword){
                    return true;
                }
                else{
                    this.errMsg="Please Confirm Password.";
                    this.errMsgFlag=true;
                    this.$toaster.warning(this.errMsg);
                    
                    return false;
                }
            }
            else{
                if(!this.phoneNumberValidation()){
                    this.errMsg="Please Enter A Valid Bangladeshi Phone Number.";
                    this.$toaster.warning(this.errMsg);
                }
                // if(!this.emailValidation()){
                //  this.errMsg="Please Enter An Valid Email Address.";
                //  this.$toaster.warning(this.errMsg);
                // }
                this.errMsgFlag=true;
                return false;
            }
        }
        else{
            if(this.name==null){
                this.errMsg="Please Enter Your Name.";
                this.$toaster.warning(this.errMsg);
            }
            // if(this.email==null){
            //  this.errMsg="Please Enter Your Mail Address.";
            //  this.$toaster.warning(this.errMsg);
            // }
            if(this.password==null){
                this.errMsg="Please Enter Password.";
                this.$toaster.warning(this.errMsg);
            }
            if(this.conPassword==null){
                this.errMsg="Please Confirm Password.";
                this.$toaster.warning(this.conPassword);
            }
            this.errMsgFlag=true;
            return false;
        }
      },
      verifyCustomerInfo(){
        if(this.signUpFormValidation()){
            let formData=new FormData();
            formData.append("name",this.name);
            formData.append("phone",this.phone);
            // formData.append("email",this.email);
            formData.append("password",this.password);
            formData.append("conPassword",this.conPassword);
            formData.append("otp",this.otp);
            this.$http.post("verify/customer/info",formData)
             .then(response=>{
                this.errMsgFlag=response.data.errMsgFlag;
                this.errMsg=response.data.errMsg;
                this.msgFlag=response.data.msgFlag;
                this.msg=response.data.msg;
                if(response.data.msgFlag==true){
                    this.$toaster.success(this.msg);
                    this.signUpForm=false;
                    this.otpForm=true;
                    this.countDown=120;
                    this.countDownTimer();
                }
                if(response.data.errMsgFlag==true){
                    this.$toaster.warning(this.errMsg);
                }
             })
             .catch(error=>{console.dir(error)})
             .finally();
        }
       },
       signUpFunction(){
        if(this.signUpFormValidation()){
            let formData=new FormData();
            formData.append("name",this.name);
            formData.append("phone",this.phone);
            // formData.append("email",this.email);
            formData.append("password",this.password);
            formData.append("conPassword",this.conPassword);
            formData.append("otp",this.otp);
            this.$http.post("verify/customer/phone",formData)
             .then(response=>{
                this.errMsgFlag=response.data.errMsgFlag;
                this.errMsg=response.data.errMsg;
                this.msgFlag=response.data.msgFlag;
                this.msg=response.data.msg;
                if(response.data.msgFlag==true){
                    this.$toaster.success(this.msg);
                    this.signUpForm=false;
                    this.otpForm=true;
                    if(response.data.loginFlag){
                        this.$store.commit('storeCustomerInfo',response.data.customerInfo);
                        this.$router.push({name:'customer.order.history'});
                        this.resetRegistrationForm();
                    }
                    this.resetRegistrationForm();
                }
                if(response.data.errMsgFlag==true){
                    this.$toaster.warning(this.errMsg);
                }
             })
             .catch(error=>{console.dir(error)})
             .finally();
        }
       },
       phoneNumberValidation()
       {
          let pattern=/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
          let flag=pattern.test(this.phone.trim());
          if(flag)
            return true;
          else
            return false;
       },
        formSubmitPrevent(e){
             e.preventDefault();
        },
    }
}
</script>

<style>

</style>